<template>
	<div>
		<TableList :form_data_seo.sync="form_data_seo" :columns="columns" :get_table_list="this.get_table_list"
			:slot_table_list="['operation','status','name','use_num','execute_end_time']"
			:submit_preprocessing="submit_preprocessing" ref="list">
			<template slot="left_btn">
				<a-button class="float_left" @click="edit" type="primary">添加人员</a-button>
			</template>
			<template slot="operation" slot-scope="data">
				<p>
					<span>
						<a @click='edit(data.record)'>
							编辑
						</a>
						<a-divider type="vertical" />
						<a-popconfirm title="您确定要删除吗?" @confirm="del(data.record)">
							<a>
								删除
							</a>
						</a-popconfirm>
					</span>
				</p>
			</template>
		</TableList>
		<EditPop ref='supervision' :submit_preprocessing='submit_string' width='50%' @handleSubmit="submit"
			:form_data="form_data" :visible.sync="visible">
			<template slot="rule_user">
				<TransferTree @change="TransferTreeChange" :treeData="treeData" :value="treeDataValue" />
				<a-input hidden="true" v-decorator="['rule_user']"></a-input>
			</template>
		</EditPop>
	</div>
</template>
<script>
	import {
		user_index
	} from "@/api/user";
	import PopTableList from "@/components/PopTableList";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import TransferTree from "@/components/TransferTreeTwo";
	import EditPop from "@/components/EditPop";
	import {
		message
	} from 'ant-design-vue';
	import {
		delConfig,
		getUserDetail,
		saveConfig,
		getUserList,
		getUserSelect
	} from "@/api/supervision";
	import {
		post_user
	} from '@/api/api'
	let columns = [{
			title: "管理者",
			dataIndex: "managers",
			ellipsis: true
		},
		{
			title: "下属",
			dataIndex: "sub_users",
			ellipsis: true
		},
		{
			title: "操作",
			scopedSlots: {
				customRender: "operation"
			},
			width: '200px'
		},
	];

	export default {
		name: "gpoodIndex",
		components: {
			Form,
			TableList,
			TransferTree,
			PopTableList,
			EditPop
		},
		data() {
			return {
				see_info: {},
				see_visible: false,
				treeData: [],
				rule_user: [],
				treeDataValue: [],
				top_count: [],
				get_table_list: getUserList,
				columns,
				treeDataValue: [],
				visible: false,
				form_data: {
					title: '添加',
					list: [{
							type: 'text',
							name: 'id',
							options: {
								initialValue: ''
							},
							hidden: true
						},
						{
							type: "select",
							name: "uids",
							title: "管理者",
							options: {
								initialValue: []
							},
							list: [],
							mode: 'multiple'
						},
						{
							type: 'slot',
							name: 'rule_user',
							title: '添加人员',
							options: {

							},
						},
					]
				},
				form_data_seo: {
					list: [{
							type: 'text',
							name: 'keyword',
							placeholder: '请输入规则名称',
							title: '名称',
						},
						{
							type: "tree-select",
							name: "department",
							title: "部门",
							options: {},
							treeData: [],
							multiple: true
						},
						{
							type: "tree-select",
							name: "role_id",
							title: "岗位职务",
							mode: "default",
							options: {},
							treeData: [],
							multiple: true
						},

					],
					...this.$config.form_data_seo
				},
				submit_string: {
					array_to_string: ['uids']
				},
				submit_preprocessing: {
					array_to_string: ['goods_name']
				}
			};
		},
		async created() {
			this.get_User_Select()
			post_user().then(res => {
				this.treeData = this.$method.get_user_department_list(res.data.list,
					'sysUserTree')
			})
			this.$method.get_role_tree().then(res => {
				this.form_data_seo.list.forEach(item => {
					if (item.name == "role_id") {
						item.treeData = res;
					}
				});
			});
			this.$method.get_department().then(res => {
				this.form_data_seo.list.forEach(item => {
					if (item.name == "department_id") {
						item.treeData = res;
					}
				});
			});
		},
		methods: {
			get_User_Select() {
				getUserSelect().then(res => {
					res.data.list.forEach(item => {
						this.form_data.list[1].list.push({
							value: item.label,
							key: item.value
						})
					})
				})
			},
			see_rule(data) {},

			TransferTreeChange(value) {
				this.rule_user = value.join(',')
			},
			edit(record) {
				if(record.id){
					getUserDetail({
							data: {
								id: record.id
							}
						}).then(res => {
							this.$refs.supervision.$refs.form.setFieldsValue({
								id: record.id
							})
						
						this.form_data.list.forEach(item => {
							if (item.name == 'rule_user') {
								let treeDataValue = [];
								res.data.list.sub_users.map(io => {
									treeDataValue.push("user_" + io.id)
									io.key = "user_" + io.id;
									io.title = io.username;
									return io;
								});
								this.rule_user = treeDataValue.join(',')
								this.treeDataValue = JSON.parse(JSON.stringify(treeDataValue));
								console.log(this.rule_user,this.treeDataValue)
								this.$forceUpdate()
								this.$refs.supervision.refs.form.setFieldsValue({
									[item.name]: treeDataValue.join(',')
								})
							}
							if (item.name == 'uids') {
								let arr = res.data.list.uids.split(',')
								this.$refs.supervision.$refs.form.setFieldsValue({
									[item.name]: arr.map(io => {
										return Number(io)
									})
								})
								this.$forceUpdate()
							}
						})
					})
				}
	
		this.visible = true
	},
	del(record) {
			delConfig({
				data: {
					id: record.id
				},
				info: true,
			}).then(res => {
				this.$refs.list.get_list();
			})
		},
		submit(e) {
			let data = e.values;
			if (!data.id) {
				data.id = '';
			}
			saveConfig({
				data: {
					"uids": data.uids ? data.uids.join(',') : '',
					"sub_users": this.rule_user,
					"id": data.id
				},
				info: true,
			}).then(res => {
				this.visible = false;
				this.$refs.list.get_list();
			})
		},

	}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.max_price_tips {
		margin-left: 99px;
		margin-top: -30px;
		font-size: 13px;
	}

	.float_left {
		margin-right: 8px;
	}

	.max_price_form {
		.item {
			display: flex;

			.right {
				flex: 1;

				.input_number {
					width: 100%;
				}
			}
		}
	}

	.page_content_top {
		display: flex;

		div {
			flex: 1;
			text-align: center;
		}

		div:nth-child(3) {
			cursor: pointer;
		}
	}

	.info_item {
		display: flex;
		font-size: 16px;
		line-height: 35px;

		span {
			flex: 1;
			text-align: right;
		}

		span:nth-child(2) {
			flex: 2;
			text-align: left;
			margin-left: 10px;
		}
	}
</style>